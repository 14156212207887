
h2.underline {
	border-bottom: 1px solid black;
}

#jarallax-container-0 {
  /*opacity: 0.2;*/
}

.jarallax-img {
      /*object-fit: contain;*/
      object-position: 80% 0% !important;
}

.btn-scroll-top {
  background-color: transparent;
}
.btn-scroll-top:hover {
  background-color: #ffe063;
}
.btn-scroll-top:hover .icon-nav {
  color: #fff;
}

.btn.action-button {
  background-color: #ffcc00;
  color: #666;
}

.icon-nav {
  font-size: 128px;
  color: #999;
  margin: 6px 0;
}      

#successMessage {
  display: none;
}

.bg-gradient-yellow {
  background-image: linear-gradient(to right, #ffcc00 0%, #ffd52d 30%, #ffe063 100%) !important;
  color: #666;
}

.bg-gradient-blue {
  background-image: linear-gradient(to right, #eee 0%, #eee 30%, #eee 100%) !important;
  color: #666;
}

.bg-blue {
  background-image: linear-gradient(to right, #669 0%, #669 30%, #669 100%) !important;
}

h3.highlight {
  color: #ffcc00;
}

.navbar-floating-logo {
  background: url(/img/beep_logo_txt_sm.png);
  background-size: cover;
  border-radius:.5rem;
  margin-right: 4px;
  height: 40px;
  width: 40px;
}

.material-icons {
  font-size: 3rem !important;
}

.accordion-item {
  background-color: #fff;
}

img.install {
  width: 150px;
}
